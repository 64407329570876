import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import '../css/locations.css';




const Locations = ({ title, locations}) => {
  
    const defaultData = useStaticQuery(graphql`
    {
      wp {
        acfOptionsContact {
          phoneData : themeOptionsContact {
            phoneLink
            phoneText
          }
          themeOptionsContact {
            caHeadquarters {
              address
              address2
              city
              postalCode
              province
            }
            usHeadquarters {
              address
              address2
              city
              state
              zip
            }
          }
        }
      }
    }
    `);
    
  const { usHeadquarters, caHeadquarters} = defaultData.wp.acfOptionsContact.themeOptionsContact;

  const DefaultPhoneNum = () => {
    // const defaultData = useStaticQuery(graphql`
    //   {
    //     wp {
    //       acfOptionsContact {
    //         phoneData : themeOptionsContact {
    //           phoneLink
    //           phoneText
    //         }
    //       }
    //     }
    //   }
    // `);
  
    const { phoneData } = defaultData.wp.acfOptionsContact;
  
    return (
      <p><a href={'tel:'+phoneData.phoneLink}>{phoneData.phoneText}</a></p>
    )
  }

  return (
    <div className="container officeContainer">
        <div className="grid smallGrid">

        <div className="col-sm-3 ">
        <a href= "#gform_wrapper_1">
          <div className="contactblock block4">
            <div className = "contacticon"><img src="https://admin.roanokegroup.com/wp-content/uploads/2024/01/contacticon.png"/></div>
            <div className = "contacttext">Get help with<br /> a <b>Quote</b>/Other</div>
          </div>
        </a>
        </div>

        <div className="col-sm-3">
        <a href="mailto:insuranceclaims@roanokegroup.com">
          <div className="contactblock block1">
            <div className = "contacticon"><img src="https://admin.roanokegroup.com/wp-content/uploads/2024/01/insurance.png"/></div>
            <div className = "contacttext">Get help with<br /><b>Insurance</b> Claims</div>
          </div>
        </a>
        </div>
        <div className="col-sm-3 ">
        <a href="mailto:submitclaim@roanokegroup.com">
          <div className="contactblock block2">
            <div className = "contacticon"><img src="https://admin.roanokegroup.com/wp-content/uploads/2024/01/bondicon.png"/></div>
            <div className = "contacttext">Get help with<br /><b>Surety Bond</b> Claims</div>
          </div>
        </a>
        </div>
        <div className="col-sm-3 ">
          <a href="/systems-support/">
          <div className="contactblock block3">
            <div className = "contacticon"><img src="https://admin.roanokegroup.com/wp-content/uploads/2024/01/supporticon-e1705975958470.png"/></div>
            <div className = "contacttext">Get help with<br /><b>System Support</b></div>
          </div>
          </a>
        </div>

      </div>


    <div className="grid smallGrid addressbar" id="abar">
      <div className="col-sm-4 addressbarentry">
        <p><b>US Corporate Headquarters</b></p>
        <p>{usHeadquarters.address}, {usHeadquarters.address2}</p>
        <p>{usHeadquarters.city}, {usHeadquarters.state} {usHeadquarters.zip}</p>
      </div>
    
      <div className="col-sm-5 addressbarentry">
      <p><b>Canada Corporate Headquarters</b></p>
        <p>{caHeadquarters.address}, {caHeadquarters.address2}</p>
        <p>{caHeadquarters.city}, {caHeadquarters.province} {caHeadquarters.postalCode}</p>
      </div>
      <div className="col-sm-3 addressbarentry addressbarend">
        <b>1-800-ROANOKE</b><br />
        <b>FAX: 1-847-969-8200</b>
      </div>
    </div>
      <div className="grid smallGrid">
        <div className="col-sm-12 maincopy">
          <h2>{title ? title : 'Office Locations'}</h2>
          <hr />
        </div>
      </div>

      <div className="grid smallGrid">
        { locations &&
            locations.map(loc => {
              const { address, faxNumber, phoneNumber } = loc.cptLocations;

              let destination = address.line1+'+'+address.line2+'+'+address.city+'+'+address.stateProvence+'+'+address.zipPostalCode;
              destination = destination.replace(/ /g, '+');

              return (
                <div key={loc.id} className="col-sm-6 col-md-3 maincopy locationBox">
                  <p><strong>{loc.title}</strong></p>
                  <p>{address.line1}{address.line2 && ', '+address.line2}</p>
                  <p>{address.city}, {address.stateProvence} {address.zipPostalCode}</p>

                  { phoneNumber ?
                      <RenderPhoneNum numbers={phoneNumber} /> :
                      <DefaultPhoneNum />
                  }
                  {/* { faxNumber &&
                    <p><a href={'tel:'+faxNumber}>Fax: {faxNumber}</a></p>
                  } */}

                  <a className="mainCTA" href={"https://www.google.com/maps/dir/?api=1&destination="+destination} target="_blank">Directions</a>
                </div>
              )
          })
        }
      </div>
    </div>
  )
}



const RenderPhoneNum = ({ numbers }) => {
  if(!numbers)
    return;

  let numbersArray = numbers.split(',');

  return (
    <React.Fragment>
      { numbersArray &&
        numbersArray.map((num, i) => {
          num = num.trim();
          return(
            <p key={i}><a href={'tel:'+num}>{num}</a></p>
          )
        })
      }
    </React.Fragment>
  )
}

export default Locations
