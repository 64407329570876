import React from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import GravityFormForm from 'gatsby-plugin-gravity-forms-ignitro'

import '../css/contact.css';

const Contact = ({ usHeadquarters, caHeadquarters, copy}) => {

  const data = useStaticQuery(graphql`
    query formQuery {
      wpGfForm(databaseId: { eq: 1 } ) {
        ...GravityFormFields
      }
    }
  `);

  return (
    <div className="container contactContainer">
      <div className="grid smallGrid">
        <div className="col-md-12 maincopy">
          <h2>Contact Us</h2>
          <hr />
          <p dangerouslySetInnerHTML={{ __html: copy }}></p>
          <GravityFormForm data={data} />
        </div>
      </div>
    </div>
  )
}

export default Contact
