import React from "react"
import { graphql } from "gatsby"

import '../css/default-hero.css';

const DefaultHero = ({ title, backgroundColor='#65869C', className }) => {

  var divStyle = { backgroundColor : backgroundColor };

  return (
    <div className={`container defaultHero ${className || ''}`} style={divStyle}>
      <div className="grid smallGrid">
        <div className="col-sm-12">
          <h1>{title}</h1>
          <div className="dots">
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default DefaultHero